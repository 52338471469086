import React, { useState } from "react";
import Mirror from './components/Mirror';
import Laptop from './components/Laptop';
import Notebook from './components/Notebook';
import Homepage from './components/Homepage';
import CardCasestudy from "./components/CardCasestudy";
import mirrorImage from '../src/assets/mirror.png';
import laptopImage from '../src/assets/laptop.png';
import notebookImage from '../src/assets/notebook.png';

import terminal from '../src/assets/terminal.png';
import figma from '../src/assets/figma.png';
import stickerImage from '../src/assets/sticker.png';
import stickerpatternImage from '../src/assets/stickerpattern.png';
import CircleIcon from './components/CircleIcon';
import allPerojectIcon from '../src/assets/figma-allproject-icon.png';

import './styles/Homepage.css';
import './styles/Aboutme.css';
import './styles/ProjectCaseStudy.css';
import './styles/Figma.css';
import './styles/Terminal.css';




 
import './App.css';

function App() {

  const [selectedContent, setSelectedContent] = useState("figma");

  

  function Homepage() {
    return <div class='grid-section'>
      <div class="grid-container">
        <div class="grid-item-title">
          <p className="welcome-text">Hi, I'm Negin! Welcome to my digital table.</p>
        </div>
        <div class="grid-item-mirror grid-item-img"><Mirror /></div>
        <div class="grid-item-laptop grid-item-img"><Laptop /></div>
        <div class="grid-item-notebook grid-item-img"><Notebook /></div>
        <div class="grid-item-mirror-lable grid-item-lable">
          <p className=" mirror-grid-text-color ">This is my reflection. Click to learn more <b>About Me</b>.</p>
        </div>
        <div class="grid-item-laptop-lable grid-item-lable">
          <p className="laptop-grid-text-color">This is my laptop. Click here to explore My <b>Case Studies and Projects</b>.</p>
        </div>
        <div class="grid-item-notebook-lable grid-item-lable">
          <p className="notebook-grid-text-color">This is my notebook. Click here to connect with me on <b>Social Media</b>.</p>
        </div>
      </div>
    </div>;}

  function Aboutme() {
      return <div className='aboutme-main-div'>
        <section className='aboutme-section' id="about-me">
          <div className='image-continer'>
            <img
              src={mirrorImage}
              alt="Mirror Design" />
          </div>
          <div className='paper-continer'>
            <img
              src={stickerImage}
              alt="Sticker" 
              className='sticker'
            />
            <img
              src={stickerpatternImage}
              alt="Sticker" 
              className='stickerapttern'
            />
            <div className='paper'>
              <p className='title'>Hi, I’m Negin Mashreghi!</p>
              <p className='body'>I’m a creative and technically skilled professional passionate about building user-centered, impactful products. With a strong foundation in product management and development, I thrive in startup environments where I help teams bring ideas to life through strategic planning, innovative problem-solving, and seamless collaboration. </p>
              <p className='body'>I’m known for helping startups create MVPs, align product strategies, and prioritize core features that drive measurable success. Whether it’s leading workshops, crafting user stories, or guiding cross-functional teams, I’m all about turning great ideas into accessible, well-designed experiences.</p>
              <p className='body'>With my background in full-stack development and agile methodologies, I bring a unique combination of creativity and technical expertise to every project. My mission? To empower startups, creators, and individuals by creating thoughtful, meaningful solutions that make a difference.</p>
            </div>
          </div>
  
        </section>
      </div>;
    }
  

    function Figma() {
      return <div className='page'>
        <div className="page-header">
          <div className="red round-btn"> </div>
          <div className="yellow round-btn" > </div>
          <div className="green round-btn"></div>
        </div>
        <div className="page-body">

          {/* side menu section */}
          <div className="side-menu">
            
            <div className="inbox-holder"/>
            <div className="lable-section lable-section-small">
                <CircleIcon size={30} fontSize={15} />
                <p className="lable small-lable">Negin’s Case Study</p>
            </div>
       
            <div className="menu-item">
              <div className="menu-icon">
                <img  src={allPerojectIcon} />
              </div>
              <div className="menu-lable">All Projects</div>
            </div>

          </div>
         
         {/* continer section */}
          <div className="continer">
            
            <div className="lable-section lable-section-big ">
                <CircleIcon size={40} fontSize={20} />
                <p className="lable big-lable">Negin’s Case Study</p>
            </div>
            <div className="casestudy-list">
              <CardCasestudy footerLabel="Hinge"/>
              <CardCasestudy footerLabel="Day one"/>
            </div>

          </div>

        </div>
      </div>;
    }

    function Terminal() {
      return <div className='page'>
         <div className="page-header">
          <div className="red round-btn"> </div>
          <div className="yellow round-btn" > </div>
          <div className="green round-btn"></div>
        </div>
         <div className="page-body" style={{backgroundColor: "#141414"}}>
          <div className="terminal">
              <div className="second-line">
                  <p>Negins-Computer:~ neginmashreghi$</p>
                  <p className="typing-1">cd MyProject</p>
              </div>
              <div className="third-line">
                  <p className="show-p-tag">Negins-Computer:MyProject neginmashreghi$</p>
                  <p className="typing-2"> ls</p>
              </div>
              <div className="show-project-list">
              
                  <a href="https://github.com/NeginMashreghi/CaptoneProject-Arduino-AWS" target="_blank">
                      <p>  &nbsp; HDL-Sensor-Project</p>
                  </a>
                  
                  <a href="https://bitbucket.org/neginmashreghi/sms-authentication/src/master/" target="_blank">
                      <p>  &nbsp; SMS-Authentication-App</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/sms-authentication-test/src/master/" target="_blank">
                      <p>  &nbsp; SMS-Authentication-Test</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/email-verification/src/master/" target="_blank">
                      <p>  &nbsp; Email-Comformation-App</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/email-verification-test/src/master/" target="_blank">
                      <p>  &nbsp; Email-Comformation-Test</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/addnumber/src/master/" target="_blank">
                      <p>  &nbsp; Auto-Deploy-Test-Bitbucket-Pipeline</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/datecalculator_frontend/src/master/" target="_blank">
                      <p>  &nbsp; Date-Calculator</p>
                  </a>
              </div>  
            </div>  
         </div>
      </div>;
    }

    const contentMap = {
      terminal: <Terminal/>,
      figma: <Figma/>,
    };
    function Project() {
      return <div className='laptop-main-div'>
        <section className='laptop-section' id="projects">
          <div className='laptop-image-continer'>
            <img
              src={laptopImage}
              alt="laptop Design" />
          </div>
          <div className='page-continer'>
            {selectedContent ? contentMap[selectedContent] : contentMap["figma"]}
            <div className='menu-btn-continer'>
              <div className="menu-btn-section img-space">
                <img
                  className='menu-img-btn  '
                  src={figma}
                  onClick={() => setSelectedContent("figma")} />
                {selectedContent == "figma" ? <div className="selection-indicator" /> : <div className="selection-indicator-empty" />}
  
              </div>
              <div className="menu-btn-section">
                <img
                  className='menu-img-btn'
                  src={terminal}
                  onClick={() => setSelectedContent("terminal")} />
                {selectedContent == "terminal" ? <div className="selection-indicator" /> : <div className="selection-indicator-empty" />}
              </div>
            </div>
          </div>
        </section>
      </div>;
    }
  
  return (
    <div className="app">
      <Homepage/>
      <Aboutme/>
      <Project setSelectedContent={setSelectedContent} />
      <div className='social-main-div'>
      <section className="social-section" id="social-links">
          <div className='image-social-continer'>
            <img
              src={notebookImage}
              alt="notebook Design" />
          </div>
          <div className='notebook-paper-continer'>
          <div  className="notebook-paper-hole-row">
            <div className="notebook-paper-hole remove"></div>
            <div className="notebook-paper-hole remove"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hole"></div>
            <div className="notebook-paper-hol remove" ></div>
            <div className="notebook-paper-hole remove"></div>
          </div>

          <div className="section-list">
            <div className="section-one"></div>
            <div className="section-two"></div>
            <div className="section-three"></div>
            <div className="section-horisantal"></div>

            <div className="section-four">
              <p className="link">Linkedin</p>
            </div>
            <div className="section-five">
              <p className="link">Email</p>
            </div>
            <div className="section-six">
              <p className="link">Github</p>
            </div>
            <div className="section-seven"></div>
            <div className="section-seven"></div>
          </div>
              
          </div>
      </section>
      </div>
      


     
    
    </div>
  );



  

 

  

 
}

export default App;





/*   
      
      <section id="social-links">
        <h2>Social Links</h2>
        <ul>
          <li><a href="https://linkedin.com">LinkedIn</a></li>
          <li><a href="mailto:negin@example.com">Email</a></li>
          <li><a href="https://github.com">GitHub</a></li>
        </ul>
      </section>
 */