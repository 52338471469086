import React from 'react';
import '../styles/Mirror.css';
import mirrorImage from '../assets/mirror.png';

const Mirror = () => {
  return (
    <div className="mirror-container">
      <img 
        src={mirrorImage} 
        alt="Mirror Design" 
        className="mirror-image mainpage-img-btn shake-on-hover" 
        onClick={() => window.scrollTo({ top: document.getElementById('about-me').offsetTop, behavior: 'smooth' })}
      />
    </div>
  );
};

export default Mirror;