import React from 'react';
import '../styles/Laptop.css';
import laptopImage from '../assets/laptop.png';

const Laptop = () => {
  return (
    <div className="laptop-container">
      <img 
        src={laptopImage} 
        alt="Laptop Design" 
        className="laptop-image mainpage-img-btn shake-on-hover" 
        onClick={() => window.scrollTo({ top: document.getElementById('projects').offsetTop, behavior: 'smooth' })}
      />
    </div>
  );
};

export default Laptop;