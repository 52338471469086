import React from "react";
import "../styles/CardCasestudy.css";

const CardCasestudy = ({ footerLabel }) => {
  return (
    <div className="card-container">
    <div className="card">Introduction</div>
    <div className="card">Research & Analysis</div>
    <div className="card">Proposed Solutions</div>
    <div className="card">Outcomes & Learnings</div>
     
      <div className="footer">{footerLabel}</div>
    </div>
  );
};

export default CardCasestudy;